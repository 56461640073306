import "./index.scss";
import CallIcon from "@material-ui/icons/Call";

function Footer() {
  return (
    <div className="footer">
      +370 682 22 436
      <a href="tel:+37068222436" className="call">
        <CallIcon fontSize="large" />
      </a>
    </div>
  );
}

export default Footer;
