import "./index.scss";

function Contacts() {
  return (
    <div className="container">
      <div className="contacts">
        <div>
          <h1>Kontaktai</h1>
          <p>Miestas: Vilnius</p>
          <a href="mailto: vilniausstaliai@gmail.com">
            El. Paštas: vilniausstaliai@gmail.com
          </a>
          <a href="tel:+37068222436"> Skambinti: +370 682 22 436</a>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
