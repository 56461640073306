import "./index.scss";

import { routedImages } from "../../constants";
import Picture from "../../components/picture";

function Gallery() {
  return (
    <div className="container">
      <div className="photos">
        {routedImages.map((x, index) => (
          <Picture key={index} index={index} />
        ))}
      </div>
    </div>
  );
}

export default Gallery;
