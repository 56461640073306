import Header from "./components/header/index";
import Footer from "./components/footer/index";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { paths } from "./constants";
import Home from "./pages/home";
import Gallery from "./pages/gallery";
import Contacts from "./pages/contacts";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Switch>
          <Route exact path={paths.home} Component={Home}>
            <Home />
          </Route>
          <Route exact path={paths.gallery} Component={Gallery}>
            <Gallery />
          </Route>
          <Route exact path={paths.contacts} Component={Contacts}>
            <Contacts />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
