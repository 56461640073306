export const paths = {
  home: "/",
  gallery: "/gallery",
  contacts: "/contacts",
};

const imageRoute = "./assets/images/Gallery/";

export const images = [
  "Photo2.jpg",
  "Photo3.jpg",
  "Photo4.jpg",

  "Photo192.jpg",
  "Photo6.jpg",
  "Photo9.jpg",

  "Photo198.jpg",
  "Photo199.jpg",
  "Photo200.jpg",

  "Photo193.jpg",
  "Photo10.jpg",
  "Photo11.JPG",
  "Photo189.jpg",
  "Photo12.JPG",
  "Photo13.JPG",
  "Photo14.JPG",
  "Photo15.JPG",
  "Photo16.JPG",
  // "Photo17.jpg",
  "Photo18.JPG",
  "Photo64.JPG",
  "Photo19.JPG",
  "Photo20.jpg",
  // "Photo21.jpg",
  "Photo22.JPG",
  "Photo23.JPG",
  // "Photo24.jpg",
  "Photo25.jpg",
  // "Photo26.jpg",
  "Photo27.JPG",
  "Photo28.JPG",
  // "Photo29.JPG",
  "Photo31.JPG",
  "Photo32.JPG",
  // "Photo33.JPG",
  // "Photo34.JPG",
  // "Photo35.JPG",
  // "Photo36.JPG",
  // "Photo37.JPG",
  "Photo38.JPG",
  "Photo39.JPG",
  "Photo40.jpg",
  "Photo41.jpg",
  "Photo42.jpg",
  "Photo43.JPG",
  "Photo44.JPG",
  // "Photo46.jpg",
  "Photo47.JPG",
  "Photo48.JPG",
  "Photo49.JPG",
  "Photo50.JPG",
  "Photo53.JPG",
  "Photo54.JPG",
  "Photo55.JPG",
  "Photo61.JPG",
  // "Photo63.JPG",
  "Photo185.jpg",
  // "Photo186.jpg",
  "Photo187.jpg",
  "Photo188.jpg",

  "Photo71.JPG",
  "Photo72.jpg",
  "Photo73.jpg",
  "Photo74.jpg",
  // "Photo77.JPG",
  // "Photo78.jpg",
  "Photo79.jpg",
  "Photo80.JPG",
  "Photo82.JPG",
  "Photo83.JPG",
  "Photo85.JPG",
  "Photo86.JPG",
  // "Photo87.jpg",
  "Photo88.JPG",
  "Photo89.JPG",
  "Photo90.jpg",
  "Photo91.jpg",
  // "Photo92.JPG",
  "Photo93.jpg",
  "Photo94.jpg",
  // "Photo95.jpg",
  // "Photo96.jpg",
  "Photo97.jpg",
  "Photo98.jpg",
  // "Photo99.jpg",
  // "Photo105.jpg",
  // "Photo106.jpg",
  "Photo107.JPG",
  "Photo108.JPG",
  "Photo109.JPG",
  "Photo110.JPG",
  "Photo112.JPG",
  "Photo113.JPG",
  "Photo114.jpg",
  "Photo115.jpg",
  "Photo116.jpg",
  "Photo117.jpg",
  "Photo118.JPG",
  // "Photo120.jpg",
  "Photo121.jpg",
  // "Photo122.JPG", nebaigtas kriaukle
  "Photo124.jpg",
  // "Photo125.jpg",
  // "Photo126.jpg",
  // "Photo127.jpg",
  "Photo128.jpg",
  "Photo129.jpg",
  "Photo130.jpg",
  // "Photo133.jpg",
  // "Photo134.jpg",
  // "Photo135.jpg",
  // "Photo136.jpg",
  // "Photo137.jpg",
  // "Photo138.jpg",
  "Photo139.jpg",
  // "Photo140.jpg",
  "Photo141.jpg",
  "Photo142.jpg",
  "Photo143.jpg",
  "Photo144.jpg",
  "Photo145.jpg",
  "Photo146.jpg",
  "Photo147.jpg",
  "Photo148.jpg",
  "Photo149.jpg",
  "Photo150.jpg",
  "Photo157.jpg",
  "Photo158.jpg",
  "Photo159.jpg",
  "Photo160.jpg",
  "Photo161.jpg",
  "Photo162.jpg",
  "Photo163.jpg",
  // "Photo164.jpg",
  "Photo165.jpg",
  "Photo166.jpg",

  // "Photo167.jpg",
  // "Photo168.jpg",
  "Photo169.jpg",
  "Photo170.jpg",
  // "Photo171.jpg",
  "Photo172.jpg",
  "Photo66.JPG",
  // "Photo102.JPG","Photo190.JPG",
  // ,
  "Photo194.jpg",
  "Photo119.JPG",
  "Photo123.jpg",
  // "Photo131.jpg",

  "Photo111.JPG",
  "Photo156.jpg",
  // "Photo151.jpg",
  "Photo152.jpg",
  "Photo153.jpg",
  "Photo154.jpg",
  "Photo155.jpg",
  "Photo56.jpg",
  // "Photo57.jpg",
  "Photo58.jpg",
  "Photo59.jpg",
  "Photo60.jpg",
  "Photo62.JPG",

  "Photo67.JPG",
  // "Photo68.JPG",
  "Photo69.JPG",
  // "Photo70.JPG",
  // "Photo75.JPG",
  "Photo76.JPG",
  "Photo81.JPG",
  "Photo84.jpg",
  "Photo100.jpg",
  // "Photo101.jpg",
  // "Photo65.JPG",
  // "Photo103.JPG",
  "Photo104.JPG",
  "Photo5.jpg",
  // "Photo8.JPG",
  "Photo30.JPG",
  "Photo45.JPG",
  "Photo201.jpg",
  "Photo52.jpg",
  // "Photo132.jpg",
  // this picture is squere uncoment then possible
  // "Photo191.JPG",
  // "Photo195.jpg",
  "Photo196.jpg",

  "Photo202.jpg",
];

export const routedImages = images.map(
  (x, idx) => require(`${imageRoute}${images[idx]}`).default
);
