import { Paper, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useHistory } from "react-router-dom";

import { paths } from "../../constants";

import { useState } from "react";
import "./index.scss";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    flexGrow: 1,
    backgroundColor: "rgba(0,0,0,0.4)",
    color: "white",
  },
}));

function Header() {
  const classes = useStyles();
  const history = useHistory();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.replace(paths[Object.keys(paths)[newValue]]);
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          centered
        >
          <Tab label="Apie" />
          <Tab label="Galerija" />
          <Tab label="Kontaktai" />
        </Tabs>
      </Paper>
    </ThemeProvider>
  );
}

export default Header;
