import "./index.scss";
import { useState } from "react";
import { routedImages } from "../../constants";

function Picture({ index }) {
  const [isActive, setActive] = useState(false);

  const addActiveClass = () => {
    setActive(!isActive);
  };

  return (
    <picture onClick={addActiveClass} className={isActive ? "zoom" : ""}>
      <img src={routedImages[index]} alt="Staliu Paslaugos" />
    </picture>
  );
}

export default Picture;
