import "./index.scss";

function Home() {
  return (
    <div className="container home">
      <div className="hero">
        <h1>
          <span className="yellowheading"> Terasų montavimas kitaip</span>
          <br />
          <span className="whiteheading"> ir kitos stalių paslaugos</span>
        </h1>
        <div className="about">
          <p>
            Atsižvelgiant į Jūsų norus galime pasiūlyti kokybišką terasų
            montavimą Jūsų namo kieme, terasoje, balkone, ant namo stogo
            (lygaus) ir t.t. Terasines lentas montuojame kitaip. <br />
            <br />
            <span className="about__bottom">
              Lentų kraštai, ar lentų užbaigimai (galai) nesiliečia su kita
              lenta, paliekamas 5-6mm tarpas, dėl to tarp lentų nesikaupia
              vanduo, šiukšlės, samanos ir t.t. Lengviau prižiūrima, tarnauja
              ilgai (svarbu prižiūrėti). Terasu lentas montuojame
              (rekomenduojame montuoti) nuo namo sienos(lango) su nedideliu
              nuolidziu, kad nubegtu vanduo. Taip pat galime pasiūlyti
              sumontuoti medinius terasų laiptus, pavėsines, tvoreles,
              dailylentes.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
